/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

import PropTypes from 'prop-types';

import { Addresstype } from 'Type/Account.type';
import { ShippingMethodsType, ShippingMethodType } from 'Type/Checkout.type';

import {
    INSTANT_PAYMENT_LOCATION_CART,
    INSTANT_PAYMENT_LOCATION_CHECKOUT, INSTANT_PAYMENT_LOCATION_MINICART
} from '../component/InstantPayment/InstantPayment.config';

export const PayPalStateType = PropTypes.shape({
    guest_cart_id: PropTypes.string.isRequired,
    widgetLocation: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    payerID: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    selectedCarrier: ShippingMethodType.isRequired,
    billing_address: Addresstype.isRequired,
    shipping_address: Addresstype.isRequired,
    shipping_methods: ShippingMethodsType,
    isCustomAddressExpanded: PropTypes.bool.isRequired,
    customer: {
        firstname: PropTypes.string.isRequired,
        lastname: PropTypes.string.isRequired,
        telephone: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired
    }
});

export const WidgetLocationType = PropTypes.oneOf([
    INSTANT_PAYMENT_LOCATION_CART,
    INSTANT_PAYMENT_LOCATION_CHECKOUT,
    INSTANT_PAYMENT_LOCATION_MINICART
]);
