/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

import { Field } from 'Util/Query';

const _getLocationFields = () => ([
    'checkout',
    'cart',
    'minicart',
    'product'
]);

const _getLocationField = () => (new Field('location').addFieldList(_getLocationFields()));

const _getPaymentMethodFields = (args, callback, instance) => [
    ...callback.apply(instance, args),
    _getLocationField()
];

export default {
    'Query/Checkout/Query': {
        'member-function': {
            _getPaymentMethodFields
        }
    }
};
