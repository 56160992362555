/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

import { PAYPAL_EXPRESS_CREDIT } from '../component/PayPal/PayPal.config';

export const selectPaymentMethod = (args, callback, instance) => {
    const { setOrderButtonVisibility } = instance.props;
    const [something] = args;
    const { code } = something;

    callback.apply(instance, args);

    if (code === PAYPAL_EXPRESS_CREDIT) {
        setOrderButtonVisibility(false);
    }
};

export class CheckoutPaymentsContainer {
    containerProps = (args, cb, instance) => {
        const { setDetailsStep, setLoading, setOrderButtonVisibility } = instance.props;
        return {
            ...cb.call(instance),
            setDetailsStep,
            setLoading,
            setOrderButtonVisibility
        };
    };
}

const { containerProps } = new CheckoutPaymentsContainer();

export const config = {
    'Component/CheckoutPayments/Container': {
        'member-function': {
            selectPaymentMethod,
            containerProps
        }
    }
};

export default config;
