/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/paypal
 */

import PropTypes from 'prop-types';
import React, { lazy, PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import CheckoutQuery from 'Query/Checkout.query';
import { CartDispatcher } from 'Store/MyAccount/MyAccount.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { TotalsType } from 'Type/MiniCart.type';
import { HistoryType } from 'Type/Router.type';
import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';
import { fetchMutation, fetchQuery } from 'Util/Request';

import PayPalQuery from '../../query/PayPal.query';
import { PayPalStateType } from '../../type/PayPal.type';
import {
    getSelectedCarrier,
    getShippingMethods,
    payPalBillingDataParser,
    payPalDataParser,
    saveGuestEmail,
    setPaymentMethodOnCart
} from '../../util/PayPal.util';
import {
    INSTANT_PAYMENT_LOCATION_CART,
    INSTANT_PAYMENT_LOCATION_MINICART
} from '../InstantPayment/InstantPayment.config';
import PayPal from './PayPal.component';
import {
    ACTION_MAP, ADDRESS_MISMATCH_ERROR_ID, PAYPAL_EXPRESS, PAYPAL_SCRIPT, SHIPPING_STEP_URL
} from './PayPal.config';

export const PayPalDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/PayPalPayment/PayPalPayment.dispatcher'
);
export const CheckoutSuccess = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-success" */
    'Component/CheckoutSuccess'
));

/** @namespace Paypal/Component/PayPal/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    cartTotals: state.CartReducer.cartTotals,
    clientId: state.ConfigReducer.paypal?.paypal_client_id,
    isSandboxEnabled: state.ConfigReducer.paypal?.paypal_sandbox_flag,
    paypal_payment_action: state.ConfigReducer.paypal?.paypal_payment_action,
    payPalState: state.PayPalPaymentReducer.state
});

/** @namespace Paypal/Component/PayPal/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setPayPalState: (state) => PayPalDispatcher.then(
        ({ default: dispatcher }) => dispatcher.setPayPalState(dispatch, state)
    ),
    setPayPalConfig: (config) => PayPalDispatcher.then(
        ({ default: dispatcher }) => dispatcher.setPayPalConfig(dispatch, config)
    ),
    setInitialPayPalState: () => PayPalDispatcher.then(
        ({ default: dispatcher }) => dispatcher.setInitialPayPalState(dispatch)
    ),
    syncCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    ),
    showNotification: (type, message, e) => dispatch(showNotification(type, message, e))
});

/** @namespace Paypal/Component/PayPal/Container */
export class PayPalContainer extends PureComponent {
    static propTypes = {
        history: HistoryType.isRequired,
        cartTotals: TotalsType.isRequired,
        payPalState: PayPalStateType.isRequired,
        setPayPalState: PropTypes.func.isRequired,
        widgetLocation: PropTypes.string.isRequired,
        showNotification: PropTypes.func.isRequired,
        setInitialPayPalState: PropTypes.func.isRequired,
        paypal_payment_action: PropTypes.string.isRequired,
        clientId: PropTypes.string,
        setLoading: PropTypes.func,
        setDetailsStep: PropTypes.func.isRequired,
        isSandboxEnabled: PropTypes.bool,
        selectedPaymentCode: PropTypes.string
    };

    static defaultProps = {
        clientId: 'sb',
        isSandboxEnabled: false,
        setLoading: () => {},
        selectedPaymentCode: PAYPAL_EXPRESS
    };

    commit = true;

    componentDidMount() {
        const { setInitialPayPalState, setPayPalState, widgetLocation } = this.props;
        const guest_cart_id = isSignedIn() ? '' : getGuestQuoteId();

        if (!document.getElementById(PAYPAL_SCRIPT)) {
            this.setPayPalScript();
        }

        const script = document.getElementById(PAYPAL_SCRIPT);

        setInitialPayPalState();
        setPayPalState({
            widgetLocation,
            guest_cart_id
        });
        script.onload = () => this.forceUpdate();

        if (widgetLocation === INSTANT_PAYMENT_LOCATION_MINICART || widgetLocation === INSTANT_PAYMENT_LOCATION_CART) {
            this.commit = false;
        }
    }

    componentWillUnmount() {
        const script = document.getElementById(PAYPAL_SCRIPT);

        if (window.location.toString().includes('/billing')) {
            if (script) {
                script.remove();
            }

            Object.keys(window).forEach((key) => {
                if (/paypal|zoid|post_robot/.test(key)) {
                    // eslint-disable-next-line fp/no-delete
                    delete window[key];
                }
            });
        }
    }

    containerProps = () => ({
        paypal: this.getPayPal(),
        environment: this.getEnvironment(),
        isDisabled: this.getIsDisabled()
    });

    setPayPalScript = () => {
        const {
            paypal_payment_action,
            cartTotals: {
                base_currency_code: currency
            }
        } = this.props;

        const params = {
            commit: this.commit,
            currency,
            intent: ACTION_MAP[paypal_payment_action],
            'client-id': this.getClientId()
        };

        const paramsString = (Object.entries(params).map(([key, value]) => `${key}=${value}`)).join('&');
        const script = document.createElement('script');

        script.src = `https://www.paypal.com/sdk/js?${paramsString}`;
        script.id = PAYPAL_SCRIPT;
        script.async = true;

        document.body.appendChild(script);
    };

    getClientId = () => {
        const {
            clientId
        } = this.props;

        if (this.getEnvironment() === 'sandbox') {
            return 'sb';
        }

        return clientId;
    };

    getIsDisabled = () => {
        const { selectedPaymentCode } = this.props;
        return selectedPaymentCode !== PAYPAL_EXPRESS;
    };

    containerFunctions = () => ({
        onError: this.onError,
        onCancel: this.onCancel,
        createOrder: this.createOrder,
        onShippingChange: this.onShippingChange,
        onApprove: this.commit ? this.onApprove : this.onOscApprove
    });

    onApprove = async (data) => {
        const {
            payPalState: {
                guest_cart_id
            } = {},
            showNotification,
            setDetailsStep
        } = this.props;
        const { orderID, payerID } = data;

        try {
            await setPaymentMethodOnCart({
                token: orderID,
                guest_cart_id,
                payerID
            });

            const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(guest_cart_id));
            const { placeOrder: { order: { order_id } } } = orderData;

            setDetailsStep(order_id);
        } catch (e) {
            showNotification('error', 'Something went wrong');
        }
    };

    onOscApprove = async (data) => {
        const { payerID } = data;
        const {
            payPalState,
            payPalState: {
                guest_cart_id
            } = {},
            showNotification,
            setPayPalState,
            history,
            cartTotals
        } = this.props;

        setPayPalState({
            payerID,
            isActiveInstant: true
        });

        try {
            const {
                shipping_address = {},
                billing_address = {},
                customer: {
                    email
                } = {}
            } = await this.getCustomerData();

            if (!Object.keys(shipping_address) || !Object.keys(billing_address)) {
                showNotification('error', __('Customer address was not retrieved from PayPal'));
                return;
            }

            if (Object.keys(shipping_address).length === 0 && Object.keys(billing_address).length > 0) {
                saveGuestEmail(email, guest_cart_id);
            } else {
                const [shipping_methods] = await Promise.all([
                    getShippingMethods(shipping_address, guest_cart_id),
                    saveGuestEmail(email, guest_cart_id)
                ]);

                if (!Object.keys(shipping_methods)) {
                    showNotification('error', __('No shipping options available for this address.'));
                    return;
                }
                const selectedCarrier = getSelectedCarrier(payPalState, shipping_methods, cartTotals);

                setPayPalState({
                    shipping_methods,
                    selectedCarrier
                });
            }

            history.push('/checkout/billing');
        } catch (e) {
            showNotification('error', 'Something went wrong');
        }
    };

    onCancel = (data) => {
        const { showNotification, setLoading } = this.props;

        setLoading(false);
        showNotification('info', 'Your payment has been canceled', data);
    };

    onError = (error) => {
        const { showNotification, setLoading, history } = this.props;

        setLoading(false);
        error.map((item) => {
            if (item.message.includes(ADDRESS_MISMATCH_ERROR_ID)) {
                history.push(SHIPPING_STEP_URL);
                showNotification('error', __('A match of the Shipping Address City, State, and Postal Code failed'));
            } else {
                showNotification('error', __('Some error appeared with PayPal', error));
            }

            return null;
        });
    };

    getPayPal = () => {
        const { paypal } = window;
        return paypal || false;
    };

    getEnvironment = () => {
        const { isSandboxEnabled } = this.props;

        return isSandboxEnabled ? 'sandbox' : 'production';
    };

    onShippingChange = async (data, actions) => {
        if (data.shipping_address.country_code !== 'US') {
            return actions.reject();
        }

        return actions.resolve();
    };

    createOrder = async () => {
        const {
            payPalState: {
                guest_cart_id
            } = {},
            setLoading,
            selectedPaymentCode,
            setPayPalState
        } = this.props;

        setLoading(true);
        const {
            paypalExpress: { token }
        } = await fetchMutation(PayPalQuery.getCreatePaypalExpressTokenMutation({
            guest_cart_id,
            express_button: false,
            code: selectedPaymentCode,
            // use_paypal_credit: this.getIsCredit(),
            urls: {
                cancel_url: 'www.paypal.com/checkoutnow/error',
                return_url: 'www.paypal.com/checkoutnow/error'
            }
        }));

        setPayPalState({
            token
        });

        return token;
    };

    getCustomerData = async () => {
        const {
            payPalState: {
                guest_cart_id,
                token
            } = {},
            setPayPalState
        } = this.props;

        const {
            getPaypalShippingInformation = {}
        } = await fetchQuery(
            PayPalQuery.getPaypalShippingInformation(
                token,
                guest_cart_id
            )
        );

        // virtual product
        if (getPaypalShippingInformation.shipping_address === null) {
            const customerData = payPalBillingDataParser(getPaypalShippingInformation, token);

            setPayPalState({ ...customerData });

            return customerData;
        }

        const customerData = payPalDataParser(getPaypalShippingInformation, token);

        setPayPalState({ ...customerData });

        return customerData;
    };

    render() {
        return (
            <PayPal
              { ...this.props }
              { ...this.containerProps() }
              { ...this.containerFunctions() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PayPalContainer));
