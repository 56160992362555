/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

import React from 'react';

import InstantPayment from '../component/InstantPayment';
import { INSTANT_PAYMENT_LOCATION_MINICART } from '../component/InstantPayment/InstantPayment.config';

class CartOverlayComponent {
    renderCartAdditional = (args, cb, instance) => {
        const { totals: { items } } = instance.props;

        if (!items || items.length < 1) {
            return null;
        }

        return (
            <div block="CartOverlay" elem="Additional">
                { instance.renderDiscount() }
                { instance.renderTax() }
                { instance.renderTotals() }
                { instance.renderOutOfStockProductsWarning() }
                <InstantPayment
                  key={ INSTANT_PAYMENT_LOCATION_MINICART }
                  widgetLocation={ INSTANT_PAYMENT_LOCATION_MINICART }
                />
                { instance.renderActions() }
            </div>
        );
    };
}

const { renderCartAdditional } = new CartOverlayComponent();

export default {
    'Component/CartOverlay/Component': {
        'member-function': {
            renderCartAdditional
        }
    }
};
