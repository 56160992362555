/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

import React from 'react';

import isMobile from 'Util/Mobile';

import InstantPayment from '../component/InstantPayment';
import { INSTANT_PAYMENT_LOCATION_CART } from '../component/InstantPayment/InstantPayment.config';

export const renderSummary = (args, callback, instance) => {
    const { totals: { grand_total } } = instance.props;

    if (isMobile.any() && grand_total) {
        return (
            <>
                 <InstantPayment
                   key={ INSTANT_PAYMENT_LOCATION_CART }
                   widgetLocation={ INSTANT_PAYMENT_LOCATION_CART }
                 />
                { callback(...args) }
            </>
        );
    }

    return callback(...args);
};

export const renderSecureCheckoutButton = (args, callback, instance) => {
    const { totals: { grand_total } } = instance.props;

    if (!isMobile.any() && grand_total) {
        return (
            <>
                { callback(...args) }
                <InstantPayment
                  key={ INSTANT_PAYMENT_LOCATION_CART }
                  widgetLocation={ INSTANT_PAYMENT_LOCATION_CART }
                />
            </>
        );
    }

    return callback(...args);
};

export default {
    'Route/CartPage/Component': {
        'member-function': {
            renderSummary,
            renderSecureCheckoutButton
        }
    }
};
