/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

export const PaymentMethodsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/PaymentMethods/PaymentMethods.dispatcher'
);

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        paymentMethods: state.PaymentMethodsReducer,
        cartItems: state.CartReducer?.cartTotals?.items
    };
};

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;
    const dispatchProps = { ...callback(...args) };

    return {
        ...dispatchProps,
        getPaymentMethods: () => {
            PaymentMethodsDispatcher.then(
                ({ default: dispatcher }) => dispatcher.handleData(dispatch, null)
            );
        }
    };
};

export const componentDidUpdate = (args, callback, instance) => {
    const self = instance;
    const {
        props: {
            cartItems,
            paymentMethods: {
                methods
            } = [],
            getPaymentMethods
        }
    } = instance;

    if (!methods.length
        && cartItems?.length
        && !self.paymentMethodsRequested
    ) {
        self.paymentMethodsRequested = true;
        getPaymentMethods();
    }

    return callback.apply(instance, args);
};

export default {
    'Component/Router/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/Router/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/Router/Container': {
        'member-function': {
            componentDidUpdate
        }
    }
};
