import {
    ProductListQuery as SourceProductListQuery
} from 'SourceQuery/ProductList.query';

/** @namespace Ktm/Query/ProductList/Query */
export class ProductListQuery extends SourceProductListQuery {
    // TODO implement logic
    _getStockItemFields() {
        return [
            'in_stock',
            'min_sale_qty',
            'max_sale_qty',
            'qty_increments',
            'erp_qty',
            'availability_status'
        ];
    }
}

export default new ProductListQuery();
