/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/paypal
 * @link https://github.com/scandipwa/base-theme
 */

import React, { PureComponent } from 'react';

import CheckoutPayments from 'Component/CheckoutPayments/CheckoutPayments.component';
import { PaymentMethodsType } from 'Type/Checkout.type';

import { WidgetLocationType } from '../../type/PayPal.type';

import './InstantPayment.style.scss';

/** @namespace Paypal/Component/InstantPayment/Component */
export class InstantPaymentComponent extends PureComponent {
     static propTypes = {
         payment_methods: PaymentMethodsType.isRequired,
         widgetlocation: WidgetLocationType.isRequired
     };

     render() {
         const { payment_methods, widgetlocation } = this.props;
         const { paymentRenderMap } = new CheckoutPayments(this.props);
         const instantMethods = payment_methods.filter((method) => method?.location?.[widgetlocation] === '1');
         const uniqueMethods = instantMethods
             .filter((compare, i, current) => current.findIndex((elem) => (elem.id === compare.id)) === i);
         const components = uniqueMethods.map(({ code }) => paymentRenderMap[code]?.call());

         return React.cloneElement(
             <div block="InstantCheckout" />,
             { widgetlocation },
             components
         );
     }
}

export default InstantPaymentComponent;
