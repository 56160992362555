/* eslint-disable no-undef */
/* eslint-disable max-lines */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import { lazy } from 'react';
import { Route } from 'react-router-dom';

import UrlRewrites from 'Route/UrlRewrites';
import {
    Breadcrumbs as SourceBreadcrumbs,
    CartPage as SourceCartPage,
    Checkout as SourceCheckout,
    CmsPage as SourceCmsPage,
    ConfirmAccountPage as SourceConfirmAccountPage,
    ContactPage as SourceContactPage,
    CookiePopup as SourceCookiePopup,
    CreateAccountPage as SourceCreateAccountPage,
    DemoNotice as SourceDemoNotice,
    Footer as SourceFooter,
    ForgotPasswordPage as SourceForgotPasswordPage,
    Header as SourceHeader,
    HomePage as SourceHomePage,
    LoginAccountPage as SourceLoginAccountPage,
    MenuPage as SourceMenuPage,
    MyAccount as SourceMyAccount,
    NavigationTabs as SourceNavigationTabs,
    NewVersionPopup as SourceNewVersionPopup,
    NotificationList as SourceNotificationList,
    OfflineNotice as SourceOfflineNotice,
    PasswordChangePage as SourcePasswordChangePage,
    ProductComparePage as SourceProductComparePage,
    Router as SourceRouter,
    SearchPage as SourceSearchPage,
    SomethingWentWrong as SourceSomethingWentWrong,
    StyleGuidePage as SourceStyleGuidePage,
    WishlistShared as SourceWishlistShared,
    withStoreRegex as sourceWithStoreRegex
} from 'SourceComponent/Router/Router.component';
import {
    ACCOUNT_FORGOT_PASSWORD,
    AFTER_ITEMS_TYPE,
    BEFORE_ITEMS_TYPE,
    CART,
    CHANGE_PASSWORD,
    CHECKOUT,
    CMS_PAGE,
    COMPARE,
    CONFIRM_ACCOUNT,
    CONTACT_PAGE,
    COOKIE_POPUP,
    CREATE_ACCOUNT,
    DEMO_NOTICE,
    FOOTER,
    HEADER,
    HOME,
    LOGIN,
    MENU,
    MY_ACCOUNT,
    MY_ACCOUNT_ADDRESS,
    MY_ACCOUNT_DOWNLOADABLE,
    MY_ACCOUNT_NEWSLETTER,
    MY_ACCOUNT_ORDER,
    MY_ACCOUNT_ORDERS,
    MY_ACCOUNT_WISHLIST,
    NAVIGATION_TABS,
    NEW_VERSION_POPUP,
    NOTIFICATION_LIST,
    SEARCH,
    SHARED_WISHLIST,
    STYLE_GUIDE,
    SWITCH_ITEMS_TYPE,
    URL_REWRITES
} from 'SourceComponent/Router/Router.config';

import './Router.override.style';

// TODO: implement CartPage
export const CartPage = SourceCartPage;

// TODO: implement Checkout
export const Checkout = SourceCheckout;

// TODO: implement CmsPage
export const CmsPage = SourceCmsPage;

// TODO: implement CookiePopup
export const CookiePopup = SourceCookiePopup;

// TODO: implement DemoNotice
export const DemoNotice = SourceDemoNotice;

// TODO: implement Header
export const Header = SourceHeader;

// TODO: implement HomePage
export const HomePage = SourceHomePage;

// TODO: implement MyAccount
export const MyAccount = SourceMyAccount;

// TODO: implement PasswordChangePage
export const PasswordChangePage = SourcePasswordChangePage;

// TODO: implement SearchPage
export const SearchPage = SourceSearchPage;

// TODO: implement ConfirmAccountPage
export const ConfirmAccountPage = SourceConfirmAccountPage;

// TODO: implement MenuPage
export const MenuPage = SourceMenuPage;

// TODO: implement Footer
export const Footer = SourceFooter;

// TODO: implement NavigationTabs
export const NavigationTabs = SourceNavigationTabs;

// TODO: implement NewVersionPopup
export const NewVersionPopup = SourceNewVersionPopup;

// TODO: implement NotificationList
export const NotificationList = SourceNotificationList;

// TODO: implement WishlistShared
export const WishlistShared = SourceWishlistShared;

// TODO: implement OfflineNotice
export const OfflineNotice = SourceOfflineNotice;

// TODO: implement ContactPage
export const ContactPage = SourceContactPage;

// TODO: implement ProductComparePage
export const ProductComparePage = SourceProductComparePage;

// TODO: implement CreateAccountPage
export const CreateAccountPage = SourceCreateAccountPage;

// TODO: implement LoginAccountPage
export const LoginAccountPage = SourceLoginAccountPage;

// TODO: implement ForgotPasswordPage
export const ForgotPasswordPage = SourceForgotPasswordPage;

// TODO: implement SomethingWentWrong
export const SomethingWentWrong = SourceSomethingWentWrong;

// TODO: implement StyleGuidePage
export const StyleGuidePage = SourceStyleGuidePage;

// TODO: implement Breadcrumbs
export const Breadcrumbs = SourceBreadcrumbs;

export const SparepartsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cart" */ 'Route/SparepartsPage'));

export const SparepartsProductPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cart" */ 'Route/SparepartsProductPage'));

export const OccasionPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "occation" */ 'Route/OccasionPage'));

export const OccasionProductPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "occation" */ 'Route/OccasionProductPage'));

// TODO: implement withStoreRegex
export const withStoreRegex = sourceWithStoreRegex;

/** @namespace Ktm/Component/Router/Component */
export class RouterComponent extends SourceRouter {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    [BEFORE_ITEMS_TYPE] = [
        {
            component: <NotificationList />,
            position: 10,
            name: NOTIFICATION_LIST
        },
        {
            component: <DemoNotice />,
            position: 15,
            name: DEMO_NOTICE
        },
        {
            component: <Header />,
            position: 20,
            name: HEADER
        },
        {
            component: <NavigationTabs />,
            position: 25,
            name: NAVIGATION_TABS
        },
        {
            component: <Breadcrumbs />,
            position: 30,
            name: Breadcrumbs
        },
        {
            component: <NewVersionPopup />,
            position: 35,
            name: NEW_VERSION_POPUP
        }
    ];

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    [SWITCH_ITEMS_TYPE] = [
        {
            component: <Route path={ withStoreRegex('/') } exact render={ (props) => <HomePage { ...props } /> } />,
            position: 10,
            name: HOME
        },
        {
            component: <Route path={ withStoreRegex('/search/:query/') } render={ (props) => <SearchPage { ...props } /> } />,
            position: 25,
            name: SEARCH
        },
        {
            component: <Route path={ withStoreRegex('/page') } render={ (props) => <CmsPage { ...props } /> } />,
            position: 40,
            name: CMS_PAGE
        },
        {
            component: <Route path={ withStoreRegex('/cart') } exact render={ (props) => <CartPage { ...props } /> } />,
            position: 50,
            name: CART
        },
        {
            component: <Route path={ withStoreRegex('/checkout/:step?') } render={ (props) => <Checkout { ...props } /> } />,
            position: 55,
            name: CHECKOUT
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/createPassword/') } render={ (props) => <PasswordChangePage { ...props } /> } />,
            position: 60,
            name: CHANGE_PASSWORD
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/create/') } render={ (props) => <CreateAccountPage { ...props } /> } />,
            position: 61,
            name: CREATE_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/login/') } render={ (props) => <LoginAccountPage { ...props } /> } />,
            position: 62,
            name: LOGIN
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/forgotpassword/') } render={ (props) => <ForgotPasswordPage { ...props } /> } />,
            position: 63,
            name: ACCOUNT_FORGOT_PASSWORD
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/confirm') } render={ (props) => <ConfirmAccountPage { ...props } /> } />,
            position: 65,
            name: CONFIRM_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/view/order_id/:orderId?') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_ORDERS } /> } />,
            position: 70,
            name: MY_ACCOUNT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/history') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_ORDERS } /> } />,
            position: 71,
            name: MY_ACCOUNT_ORDERS
        },
        {
            component: <Route path={ withStoreRegex('/downloadable/customer/products') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_DOWNLOADABLE } /> } />,
            position: 72,
            name: MY_ACCOUNT_DOWNLOADABLE
        },
        {
            component: <Route path={ withStoreRegex('/wishlist') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_WISHLIST } /> } />,
            position: 73,
            name: MY_ACCOUNT_WISHLIST
        },
        {
            component: <Route path={ withStoreRegex('/customer/address') } render={ (props) => <MyAccount { ...props } selectedTab={ ADDRESS_BOOK } /> } />,
            position: 74,
            name: MY_ACCOUNT_ADDRESS
        },
        {
            component: <Route path={ withStoreRegex('/newsletter/manage') } render={ (props) => <MyAccount { ...props } selectedTab={ NEWSLETTER_SUBSCRIPTION } /> } />,
            position: 75,
            name: MY_ACCOUNT_NEWSLETTER
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/:tab?') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 76,
            name: MY_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/menu') } render={ (props) => <MenuPage { ...props } /> } />,
            position: 80,
            name: MENU
        },
        {
            component: <Route path={ withStoreRegex('/wishlist/shared/:code') } render={ (props) => <WishlistShared { ...props } /> } />,
            position: 81,
            name: SHARED_WISHLIST
        },
        {
            component: <Route path={ withStoreRegex('/contact') } render={ (props) => <ContactPage { ...props } /> } />,
            position: 82,
            name: CONTACT_PAGE
        },
        {
            component: <Route path={ withStoreRegex('/compare') } render={ (props) => <ProductComparePage { ...props } /> } />,
            position: 83,
            name: COMPARE
        },
        {
            component: <Route path={ withStoreRegex('/styleguide') } render={ (props) => <StyleGuidePage { ...props } /> } />,
            position: 84,
            name: STYLE_GUIDE
        },
        {
            component: <Route path={ withStoreRegex('/spareparts') } exact render={ (props) => <SparepartsPage { ...props } /> } />,
            position: 90,
            name: CART
        },
        {
            component: <Route path={ withStoreRegex('/spareparts/:genurl/:id') } exact render={ (props) => <SparepartsProductPage { ...props } /> } />,
            position: 92,
            name: 'SPAREPARTS'
        },
        {
            component: <Route path={ withStoreRegex('/lager-und-occasion-motorraeder') } exact render={ (props) => <OccasionPage { ...props } /> } />,
            position: 95,
            name: 'OCCASION'
        },
        {
            component: <Route path={ withStoreRegex('/lager-und-occasion-motorraeder/index/detail/id/:id') } exact render={ (props) => <OccasionProductPage { ...props } /> } />,
            position: 96,
            name: 'OCCASIONPRODUCT'
        },
        {
            component: <Route render={ (props) => <UrlRewrites { ...props } /> } />,
            position: 1000,
            name: URL_REWRITES
        }
    ];

    // eslint-disable-next-line no-undef
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    // eslint-disable-next-line no-undef
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    [AFTER_ITEMS_TYPE] = [
        {
            component: <Footer />,
            position: 10,
            name: FOOTER
        },
        {
            component: <CookiePopup />,
            position: 20,
            name: COOKIE_POPUP
        }
    ];
}

export default RouterComponent;
